import { Button, Col, Pagination, Row, Spin, Switch, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { History } from '../../_helpers/history';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Activities } from '../../components/activities';
import { Amenities } from '../../components/amenities';
import { ApproveSwitch } from '../../components/approveSwitch/approveSwitch';
import { Image } from '../../components/Image';
import { NewDiningSchedule } from '../../components/newDiningSchedule';
import { PdfComponent } from '../../components/pdfComponent/pdfViewer';
import { DisabledFeaturesToggler } from '../../components/toggleButtonComp';
import { PathConstants } from '../../constants/pathConstants';
import { IFacilityDetails } from '../facilityPage/facilityInterface';
import { setGetFacilityByIdPayload } from '../facilityPage/facilityPage.constant';
import {
  activateFacility,
  approveFacility,
  facilityInfo,
  getFacilityById,
  InactivateFacility,
  onChangeFacilityDemo,
} from '../facilityPage/slices/facilitySlice';
import { activityPageChange, facilityDetails } from './facilityDetailsSlice';
import './index.scss';

export const FacilityDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState('1');
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewDocuments, setViewDocuments] = useState<number>(-1);
  const [selectedFile, setSelectedFile] = useState('');

  const facilityInformation: IFacilityDetails = useSelector(facilityInfo);
  const {
    facilityById,
    isApprovingFacilityRequest,
    facilityFilter,
    isFacilityDeleteRequest,
    isGetFacilityById,
  } = facilityInformation;
  const [demo, setDemo] = useState(facilityById?.isDemoFacility);

  const { activityTotalPages, activitySize, activityCurrent } =
    useAppSelector(facilityDetails);
  const [disabledFeatures, setDisabledFeatures] = useState<string[] | []>([]);
  const facilityId = searchParams.get('id') || '';
  useEffect(() => {
    dispatch(getFacilityById(setGetFacilityByIdPayload(facilityId, false)));
  }, []);
  useEffect(() => {
    setDemo(facilityById?.isDemoFacility ? true : false);
  }, [facilityById]);
  const onInActivate = async () => {
    const id = searchParams.get('id') || '';

    await dispatch(InactivateFacility({ id: id, filter: facilityFilter }));
    dispatch(getFacilityById(setGetFacilityByIdPayload(facilityId, false)));
  };
  const onActivate = async () => {
    const id = searchParams.get('id') || '';

    await dispatch(activateFacility({ id: id, filter: facilityFilter }));
    dispatch(getFacilityById(setGetFacilityByIdPayload(facilityId, false)));
  };
  const { TabPane } = Tabs;
  const onPageChange = (page: number) => {
    dispatch(activityPageChange(page));
  };
  function callback(key: string) {
    setCurrentTab(key);
  }
  const handleViewClick = (index: number) => {
    setViewDocuments(index);
  };
  const closeViewer = () => {
    setSelectedFile('');
  };
  const onDemoChange = async () => {
    const id = searchParams.get('id') || '';

    await dispatch(
      onChangeFacilityDemo({
        id: id,
        filter: facilityFilter,
        isDemoFacility: !demo,
      })
    );
    dispatch(getFacilityById(setGetFacilityByIdPayload(facilityId, false)));
  };
  return (
    <div className="facility-details-container">
      {isGetFacilityById ? (
        <Row className="display-flex justify-center align-center w-full h-full">
          <Spin />
        </Row>
      ) : (
        <>
          {viewDocuments >= 0 && (
            <div
              onClick={() => handleViewClick(-1)}
              className="amenity-drop-down-background-wrap"
            ></div>
          )}
          {selectedFile ? (
            <div style={{ height: '100vh', overflow: 'auto' }}>
              <PdfComponent url={selectedFile} close={closeViewer} />
            </div>
          ) : (
            <>
              <Row className="facility-details-heading-row">
                <Col className="facility-details-heading-back-col" span={12}>
                  {' '}
                  <img
                    className="cursor-pointer"
                    src="/assets/back-icon.svg"
                    alt="back"
                    onClick={() => History.navigate(PathConstants.FACILITIES)}
                  />
                  <div className="facility-details-header-name raleway-16">
                    {facilityById?.facilityName}
                    {demo && <span className="facility-demo-tag">Demo</span>}
                  </div>
                </Col>
                <Col className="facility-details-heading-button-col" span={12}>
                  {' '}
                  <ApproveSwitch
                    onSubmit={
                      facilityById.status === 'ACTIVE'
                        ? onInActivate
                        : onActivate
                    }
                    backgroundTxt="Status"
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    checked={facilityById.status === 'ACTIVE' ? true : false}
                    loader={isFacilityDeleteRequest}
                  />
                  {!facilityById?.approved && (
                    <Button
                      loading={isApprovingFacilityRequest}
                      className="generic-tab-button inter-12-bold facility-add-button"
                      onClick={() => dispatch(approveFacility(facilityId))}
                    >
                      Approve
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="approve-switch-container" span={24}>
                  {' '}
                  <span
                    style={{
                      marginRight: '10px',
                      color: demo ? 'orange' : '#000',
                    }}
                  >
                    {demo ? 'Demo On' : 'Demo Off'}
                  </span>
                  <Switch
                    checked={demo}
                    onChange={() => onDemoChange()}
                    loading={false}
                    className="approve-switch"
                    style={{
                      backgroundColor: demo ? 'orange' : undefined,
                      borderColor: demo ? 'orange' : undefined,
                    }}
                  />
                </Col>
              </Row>
              <Row className="facility-details-row margin-top-40">
                <Col className="" span={8}>
                  <Row className="facility-details-row">
                    <Col
                      className="facility-details-title-field inter-12 display-flex justify-initial"
                      span={6}
                    >
                      Facility ID:
                    </Col>
                    <Col
                      className="display-flex justify-initial inter-12 facility-details-title-description"
                      span={18}
                    >
                      {facilityById?.facilityId}
                    </Col>
                  </Row>
                  <Row className="facility-details-row margin-top-13">
                    <Col
                      className="facility-details-title-field inter-12 display-flex justify-initial"
                      span={6}
                    >
                      Facility Number:
                    </Col>
                    <Col
                      className="display-flex justify-initial inter-12 facility-details-title-description"
                      span={18}
                    >
                      {facilityById?.facilityNumber}
                    </Col>
                  </Row>
                  <Row className="facility-details-row margin-top-13">
                    <Col
                      className="facility-details-title-field inter-12 display-flex justify-initial"
                      span={6}
                    >
                      Email Address:
                    </Col>
                    <Col
                      className="display-flex justify-initial inter-12 facility-details-title-description"
                      span={18}
                    >
                      {facilityById?.email}
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row className="facility-details-title-field inter-12 display-flex justify-initial">
                    Location Address:
                    <Row className="facility-details-row ">
                      {' '}
                      <Col
                        className="display-flex  align-initial justify-initial inter-12 facility-details-title-description"
                        span={15}
                      >
                        {facilityById?.facilityAddress?.addressLine1},&nbsp;
                        {facilityById?.facilityAddress?.addressLine2},&nbsp;
                        {facilityById?.facilityAddress?.addressLine3}
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Row className="facility-details-disable-feat-div">
                      {facilityById?.disabledFeatures && (
                        <DisabledFeaturesToggler
                          getDisabledFeatures={setDisabledFeatures}
                          parentDivClass={'disabled-feature-parent'}
                          checkboxDivClass={'disabled-features-normal-checkbox'}
                          checkboxClass={'normal-checkbox-label'}
                          toggleDivClass={'toggle-checkbox-label'}
                          data={facilityById?.disabledFeatures}
                          jukeboxSubscribed={facilityById?.isJukeboxSubscribed}
                        />
                      )}
                    </Row>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row className="facility-img-row">
                    <Col className="facility-img-col" span={8}>
                      {' '}
                      <Image
                        id={facilityById?.coverPicId?.toString()}
                        propClassNoData={'loacl-buissness-amenity-img-no-data'}
                        propClass={'facility-image-style'}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="facility-details-tab">
                {' '}
                <Tabs
                  className="inter-12 user-tabs facility-details-tab"
                  defaultActiveKey="1"
                  onChange={callback}
                >
                  <TabPane tab="Activities" key="1">
                    <Activities />
                  </TabPane>
                  <TabPane tab="Amenities" key="2">
                    <Amenities
                      viewDocuments={viewDocuments}
                      setViewDocuments={setViewDocuments}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                    />
                  </TabPane>{' '}
                  <TabPane tab="Dining Schedules" key="3">
                    <NewDiningSchedule />
                  </TabPane>{' '}
                </Tabs>
                <Row
                  style={{
                    padding: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {currentTab === '1' &&
                    activitySize * activityTotalPages > 5 && (
                      <Pagination
                        showSizeChanger={false}
                        pageSize={activitySize}
                        current={activityCurrent}
                        onChange={onPageChange}
                        total={activitySize * activityTotalPages}
                        showTitle={false}
                      />
                    )}
                </Row>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};
